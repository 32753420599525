// dependencies
import React, { useState, useCallback } from 'react'
import { Select, MenuItem, FormControl, makeStyles, Typography, Box } from '@material-ui/core'
// helpers
import { slugify } from '@helpers/string-helper'
// hooks
import { useCommonQuestionsCxt } from '../../../lib/context/commonQuestions'

const useStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  formControl: {
    minWidth: 180,
    maxWidth: 400,
  },
  label: {
    fontSize: '12px',
    color: '#585860',
    marginBottom: '0.2rem',
  },
  menu: {
    '& > div': {
      minWidth: 180,
      paddingRight: 0,
    },
  },
  menuItem: {
    fontSize: '0.875rem',
  },
}))

const Dropdown = () => {
  const { data, setActiveNav, offset, sectionRefs } = useCommonQuestionsCxt()
  const [value, setValue] = useState(data[0]?.Heading || '')
  const classes = useStyles()

  const handleChange = useCallback(
    event => {
      if (setActiveNav) {
        const heading = event.target.value
        const sectionId = `${slugify(heading)}-section`
        const navId = `${slugify(heading)}-nav`
        setValue(heading)
        const { current: section } = sectionRefs.find(({ current }) => current.id === sectionId)
        if (typeof window !== 'undefined' && section) {
          const top = section.getBoundingClientRect().top + window.pageYOffset - offset
          window.scrollTo({ top, behavior: 'smooth' })
          setTimeout(() => {
            setActiveNav(navId)
          }, 500)
        }
      }
    },
    [setActiveNav, setValue, offset, sectionRefs],
  )

  return (
    <Box className={classes.wrapper}>
      <Typography component="label" className={classes.label}>
        Question Categories
      </Typography>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select className={classes.menu} value={value} onChange={handleChange}>
          {data.map(({ Heading, id }) => (
            <MenuItem className={classes.menuItem} key={`${id}-mobile-dropdown`} value={Heading}>
              {Heading}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default Dropdown
