// dependencies
import React, { useEffect, useRef, useCallback, createRef } from 'react'
import { makeStyles, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
// helpers
import { slugify } from '@helpers/string-helper'
import { mergeRefs, throttle } from '@helpers/events'
// context
import { useCommonQuestionsCxt } from '@context/commonQuestions'
// components
import Accordion from './Accordion'

const useStyles = makeStyles(theme => ({
  listWrapper: {
    width: '100%',
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
    },
  },
  textWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  listHeading: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h5.fontSize,
  },
}))

const AccordionList = ({ data: AccordionsList }) => {
  const { setActiveNav, pushSectionRef } = useCommonQuestionsCxt()
  const sectionRef = useRef()
  const skip = typeof setActiveNav !== 'function'
  const [inViewRef, inView] = useInView({ threshold: 0.8, skip })
  const classes = useStyles()
  const accordionHeading = AccordionsList.Title.replace(/Common Question: /, '')
  const sectionId = `${slugify(accordionHeading)}-section`

  const scrollHandler = useCallback(() => {
    if (typeof window !== 'undefined' && window.pageYOffset >= sectionRef.current.offsetTop && inView) {
      setActiveNav(`${slugify(accordionHeading)}-nav`)
    }
  }, [inView, setActiveNav, accordionHeading])

  useEffect(() => {
    if (typeof window !== 'undefined' && !skip) {
      const onScroll = throttle(scrollHandler, 250)
      window.addEventListener('scroll', onScroll)
      return () => window.removeEventListener('scroll', onScroll)
    }
    return () => {}
  }, [scrollHandler, skip])

  useEffect(() => {
    if (pushSectionRef && sectionRef.current) {
      pushSectionRef(sectionRef)
    }
  }, [AccordionsList, pushSectionRef])

  return (
    <section id={sectionId} ref={mergeRefs(inViewRef, sectionRef)}>
      <div className={classes.textWrapper}>
        <Typography component="h3" className={classes.listHeading}>
          {accordionHeading}
        </Typography>
      </div>
      <div className={classes.listWrapper}>
        {AccordionsList.Accordions &&
          AccordionsList.Accordions.map((data, index) => {
            const id = data.contentful_id || data.id
            return <Accordion data={data} index={index} key={id} withMarginTop={false} />
          })}
      </div>
    </section>
  )
}

AccordionList.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AccordionList
