// dependencies
import React, { useCallback } from 'react'
import { makeStyles, ListItem, ListItemText, ListItemSecondaryAction, Box } from '@material-ui/core'
import classNames from 'classnames'
import PropTypes from 'prop-types'
// icons
import { NavigateNext } from '@material-ui/icons'
// helpers
import { slugify } from '@helpers/string-helper'
// hooks
import { useCommonQuestionsCxt } from '../../../lib/context/commonQuestions'

const useStyles = makeStyles(theme => ({
  listItem: {
    transition:
      'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      '& span': {
        color: theme.palette.text.secondary,
      },
    },
  },
  activeItem: {
    backgroundColor: theme.palette.primary.main,
    '& span': {
      color: theme.palette.text.secondary,
    },
  },
  icon: {
    color: theme.palette.text.secondary,
  },
  action: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
  },
}))

const NavItem = ({ heading }) => {
  const { drawerWidth, setActiveNav, offset, activeNav, sectionRefs } = useCommonQuestionsCxt()
  const classes = useStyles({ drawerWidth, offset })

  const sectionId = `${slugify(heading)}-section`
  const navId = `${slugify(heading)}-nav`
  const isActive = navId === activeNav

  const onClick = useCallback(() => {
    const { current: section } = sectionRefs.find(({ current }) => current.id === sectionId)
    if (typeof window !== 'undefined' && setActiveNav && section) {
      const top = section.getBoundingClientRect().top + window.pageYOffset - offset
      window.scrollTo({ top, behavior: 'smooth' })
      setTimeout(() => {
        setActiveNav(navId)
      }, 500)
    }
  }, [sectionId, navId, offset, setActiveNav, sectionRefs])

  return (
    <ListItem
      onClick={onClick}
      className={classNames(classes.listItem, { [classes.activeItem]: isActive })}
      divider
      button
    >
      <ListItemText primary={heading} />
      <ListItemSecondaryAction className={classes.action}>
        <NavigateNext className={classes.icon} />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

NavItem.propTypes = {
  heading: PropTypes.string.isRequired,
}

export default NavItem
