// dependencies
import React, { useEffect, useState, useCallback } from 'react'
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core'
import classNames from 'classnames'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
// hooks
import { useCommonQuestionsCxt } from '@context/commonQuestions'

const useStyles = makeStyles(theme => ({
  accordion: {
    marginTop: ({ withMarginTop }) => (withMarginTop ? theme.spacing(2) : 0),
    outline: 'none !important',
    '& *': {
      outline: 'none !important',
    },
  },
  borderNone: {
    borderBottom: 'solid 1px transparent',
    transition: 'border-bottom .3s ease',
    '&:hover': {
      borderBottom: `solid 1px ${theme.palette.primary.main}`,
    },
    '&:before': {
      backgroundColor: 'transparent',
    },
  },
  heading: {
    fontWeight: '600',
    fontSize: theme.typography.subtitle1.fontSize,
  },
  summary: {
    position: 'relative',
    borderBottom: 'none',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  desktopSummary: {
    '&:before': {
      content: '"•"',
      position: 'absolute',
      left: 2,
      top: '50%',
      transform: 'translateY(-50%)',
      fontSize: '1.25rem',
    },
  },
  expandedSummary: {
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
  },
  expandedContent: {
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
  },
  content: {
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      padding: '1.25rem 0',
    },
  },
}))

// eslint-disable-next-line no-shadow
const Accordion = ({ data: { Accordion }, children = null, withMarginTop = true }) => {
  const { activeQuestionId, setActiveQuestionId } = useCommonQuestionsCxt()
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles({ withMarginTop, expanded })
  const accordionID = Accordion?.contentful_id || Accordion?.id
  const aria = `${accordionID}-content`
  const id = `${accordionID}-header`

  const customProps = {
    elevation: 0,
    square: true,
  }

  const isCurrent = activeQuestionId === accordionID
  const setAsCurrent = useCallback(() => {
    if (setActiveQuestionId) {
      setActiveQuestionId(accordionID)
    }
  }, [accordionID, setActiveQuestionId])

  useEffect(() => {
    if (Accordion?.Hidden && typeof Accordion?.Hidden === 'boolean') {
      setExpanded(!Accordion.Hidden)
    }
  }, [Accordion])

  useEffect(() => {
    setExpanded(isCurrent)
  }, [isCurrent, setExpanded])

  const onKeyDown = useCallback(
    event => {
      const code = event.keyCode || event.which
      if (code === 13 || code === 32) {
        setExpanded(!expanded)
      }
      if (code === 27) {
        setExpanded(false)
      }
    },
    [expanded],
  )

  const onChange = useCallback(
    (event, isExpanded) => {
      setExpanded(isExpanded)
      setAsCurrent()
    },
    [setAsCurrent],
  )

  if (Accordion === null) return null

  return (
    <MuiAccordion
      expanded={expanded}
      onChange={onChange}
      className={classNames(classes.accordion, { [classes.borderNone]: !expanded })}
      {...customProps}
    >
      <AccordionSummary
        className={classNames(classes.summary, {
          [classes.expandedSummary]: expanded,
          [classes.desktopSummary]: isDesktop,
        })}
        IconButtonProps={{ disableRipple: true }}
        onKeyDown={onKeyDown}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={aria}
        id={id}
      >
        <Typography className={classes.heading}>{Accordion?.Heading}</Typography>
      </AccordionSummary>
      <AccordionDetails className={classNames(classes.content, { [classes.expandedContent]: expanded })}>
        {Accordion?.childStrapiMarkdownRemark && Accordion?.childStrapiMarkdownRemark?.childMarkdownRemark && (
          <div
            id={accordionID}
            role="region"
            dangerouslySetInnerHTML={{
              __html: Accordion.childStrapiMarkdownRemark.childMarkdownRemark.html,
            }}
          />
        )}
        {children && <div className="contentful-accordion-content">{children}</div>}
      </AccordionDetails>
    </MuiAccordion>
  )
}

Accordion.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.node,
  withMarginTop: PropTypes.bool,
}

export default Accordion
