// dependencies
import React, { useEffect } from 'react'
import { makeStyles, Box, Typography, Hidden, Divider } from '@material-ui/core'
// helpers
import { slugify } from '@helpers/string-helper'
// hooks
import { useCommonQuestionsCxt } from '@context/commonQuestions'
// components
import AccordionList from './AccordionList'
import Dropdown from './Dropdown'

const useStyles = makeStyles(theme => ({
  wrapper: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  pageTitle: {
    textTransform: 'uppercase',
    fontWeight: '400',
    color: theme.palette.primary.main,
    fontSize: '2.2rem',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.8rem',
    },
  },
}))

const Content = () => {
  const { drawerWidth, setActiveNav, setActiveQuestionId, data } = useCommonQuestionsCxt()
  const classes = useStyles({ drawerWidth })
  useEffect(() => {
    if (setActiveNav && data[0]) {
      setActiveNav(`${slugify(data[0].Heading)}-nav`)
    }
  }, [setActiveNav, data])

  useEffect(() => {
    if (data[0]?.Accordions[0]?.id) {
      setActiveQuestionId(data[0].Accordions[0].id)
    }
  }, [setActiveQuestionId, data])

  return (
    <Box className={classes.wrapper}>
      <Box>
        <Typography component="h1" className={classes.pageTitle}>
          Common Questions
        </Typography>
        <Hidden lgUp>
          <Dropdown />
          <Divider />
        </Hidden>
      </Box>
      <Box>
        {data.map(content => (
          <AccordionList key={content.id.concat('-content')} data={content} setActiveNav={setActiveNav} />
        ))}
      </Box>
    </Box>
  )
}

export default Content
