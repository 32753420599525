// dependencies
import React, { useState, useCallback } from 'react'
import Helmet from 'react-helmet'
import { makeStyles, Box, Hidden } from '@material-ui/core'
import PropTypes from 'prop-types'
import { graphql, StaticQuery } from 'gatsby'
// Context
import { CommonQuestionsProvider } from '@context/commonQuestions'
// components
import Layout from '../../components/layout'
import Drawer from '../../components/customer-service/common-questions/Drawer'
import Content from '../../components/customer-service/common-questions/Content'
import Breadcrumbs from '../../components/customer-service/common-questions/Breadcrumbs'

const useStyles = makeStyles(theme => ({
  wrapper: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
    '& h5': {
      fontSize: theme.typography.h6.fontSize,
      fontWeight: '400',
      marginBottom: theme.spacing(1),
    },
  },
}))

const CommonQuestions = ({ props }) => {
  const [activeNav, setActiveNav] = useState('')
  const [activeQuestionId, setActiveQuestionId] = useState('')
  const [sectionRefs, setSectionRefs] = useState([])
  const classes = useStyles()

  const pushSectionRef = useCallback(
    newRef => {
      setSectionRefs(refs => [...refs, newRef])
    },
    [setSectionRefs],
  )

  return (
    <Layout {...props}>
      <Helmet title="Common Questions - Rooms To Go" />
      <StaticQuery
        query={graphql`
          query {
            allStrapiAccordionList(filter: { Title: { regex: "/Common Question:/" } }) {
              nodes {
                Title
                id
                Accordions {
                  Accordion {
                    Heading
                    Title
                    id
                    childStrapiMarkdownRemark {
                      childMarkdownRemark {
                        html
                      }
                    }
                    Hidden
                  }
                }
              }
            }
          }
        `}
        render={({ allStrapiAccordionList: { nodes } }) => {
          const contextValue = {
            activeNav,
            setActiveNav,
            activeQuestionId,
            setActiveQuestionId,
            drawerWidth: 260,
            data: nodes,
            offset: 128,
            sectionRefs,
            pushSectionRef,
          }
          return (
            <CommonQuestionsProvider value={contextValue}>
              <Breadcrumbs />
              <Box className={classes.wrapper}>
                <Hidden mdDown>
                  <Drawer />
                </Hidden>
                <Content />
              </Box>
            </CommonQuestionsProvider>
          )
        }}
      />
    </Layout>
  )
}

CommonQuestions.propTypes = {
  props: PropTypes.any,
}

export default CommonQuestions
