// dependencies
import React from 'react'
import { makeStyles, Divider, List, Box } from '@material-ui/core'
// hooks
import { useCommonQuestionsCxt } from '@context/commonQuestions'
// components
import NavItem from './NavItem'

const useStyles = makeStyles(theme => ({
  wrapper: {
    borderRight: `1px solid #dedede`,
    minWidth: ({ drawerWidth }) => drawerWidth,
    position: 'relative',
    outline: 'none !important',
    '& *': {
      outline: 'none !important',
    },
  },
  list: {
    paddingTop: 0,
    position: 'sticky',
    top: ({ offset }) => offset,
    bottom: 20,
  },
}))

const Drawer = () => {
  const { drawerWidth, data, offset } = useCommonQuestionsCxt()
  const classes = useStyles({ drawerWidth, offset })
  return (
    <Box component="nav" className={classes.wrapper}>
      <Divider />
      <List className={classes.list}>
        {data.map(({ Title, id }) => (
          <NavItem key={id} heading={Title.replace(/Common Question: /, '')} />
        ))}
      </List>
    </Box>
  )
}

export default Drawer
